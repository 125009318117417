<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card :loading="loading" :disabled="loading || loading2">
                    <v-row>
                        <v-col cols="12">
                            <v-img
                                height="70"
                                src="@/assets/images/ibelsa_large.png"
                                contain
                                style="margin-top: 30px; margin-bottom: 30px;"
                            ></v-img>
                        </v-col>
                    </v-row>

                    <v-toolbar dark flat>
                        <v-toolbar-title>IBELSA Schnittstelle</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="getSettings" :loading="loading" :disabled="loading || loading2">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <!-- Settings -->
                    <v-card-title>{{ $t('settings.lang_settings') }}</v-card-title>

                    <v-card-text>
                        <v-text-field outlined :label="$t('settings.lang_apiHost')" disabled :placeholder="apiHost" v-model="apiHost"></v-text-field>
                        <v-text-field outlined :label="$t('settings.lang_clientKey')" placeholder="82B4DC42-2145-710E..." v-model="clientKey"></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <!-- Cashmaster -->
                    <v-card-title>Cashmaster</v-card-title>
                    <v-card-subtitle>kostenpflichtig</v-card-subtitle>

                    <v-card-text>
                        <v-alert text color="info" v-html="$t('settings.lang_ibelsaCashmasterAlert')"></v-alert>

                        <v-checkbox :label="$t('settings.lang_cashmasterActive')" v-model="useCashmaster"></v-checkbox>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" :disabled="loading2" :loading="loading2" @click="saveSettings">{{ $t('generic.lang_save') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
    name: "IbelsaSettings",

    data() {
        return {
            loading: false,
            loading2: false,
            clientKey: "",
            apiHost: "https://rooms.ibelsa.com/api/cash/2.0/xml/",
            useCashmaster: false
        }
    },

    mounted() {
        this.getSettings();
    },

    methods: {
        getSettings() {
            this.loading = true;

            this.axios.post(ENDPOINTS.IBELSA.SETTINGS.GET).then((res) => {
                if(res.data.success) {
                    this.clientKey = res.data.settings.clientKey;
                    this.useCashmaster = res.data.settings.useCashmaster === 1;
                }

                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        saveSettings() {
            this.loading2 = true;

            this.axios.post(ENDPOINTS.IBELSA.SETTINGS.UPDATE, {
                clientKey: this.clientKey,
                useCashmaster: this.useCashmaster ? 1 : 0
            }).then(() => {
                this.loading2 = false;
            }).catch(() => {
                this.loading2 = false;
            })
        }
    }
}
</script>